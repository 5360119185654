import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (

  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">AI Revolutionizing Rockets</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="Sep 26, 2021" text="The Role of GPT-3 and OpenAI in Rocket Science" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="Sep 26, 2021" text="How GPT-3 and OpenAI are Powering Rocket Navigation Systems" />
        <Article imgUrl={blog03} date="Sep 26, 2021" text="AI Assistance in Rocket Design and Optimization" />
        <Article imgUrl={blog04} date="Sep 26, 2021" text="Exploring the Integration of GPT-3 in Rocket Propulsion" />
        <Article imgUrl={blog05} date="Sep 26, 2021" text="The Future of Rocketry: AI-Driven Innovations" />
      </div>
    </div>
  </div>
);
export default Blog;
