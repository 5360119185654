import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'AI-powered Rockets for Enhanced Performance',
    text: 'Experience the power of artificial intelligence (AI) in rocket technology. Our advanced AI algorithms optimize rocket performance, enabling higher efficiency and improved outcomes.',
  },
  {
    title: 'Unleash the Potential of Intelligent Rocket Systems',
    text: 'Harness the capabilities of intelligent rocket systems. Our AI-driven solutions revolutionize rocket design, navigation, and control, pushing the boundaries of space exploration.',
  },
  {
    title: 'Next-generation Rocket Propulsion with AI',
    text: 'Explore the fusion of AI and rocket propulsion. Our cutting-edge technology leverages AI to enhance thrust, fuel efficiency, and overall rocket propulsion systems.',
  },
  {
    title: 'AI-guided Rocket Engineering for Breakthroughs',
    text: 'Embrace the future of rocket engineering with AI guidance. Our AI algorithms assist in designing and developing innovative rocket structures and components, paving the way for groundbreaking achievements.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future of Rocket Technology Powered by AI</h1>
      <p>Experience the advancements in AI-driven rocket systems</p>

    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
