import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (

  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="AI in Rockets" text="AI plays a crucial role in modern rocket technology. It revolutionizes various aspects of space exploration and enables unprecedented advancements. With AI-powered systems, rockets can achieve enhanced efficiency, autonomy, and decision-making capabilities. These intelligent systems assist in mission planning, trajectory optimization, data analysis, and much more. AI empowers rockets to adapt to changing conditions, respond to anomalies, and improve overall performance." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Unleashing the Potential of AI in Rockets</h1>
      <p>Explore the Possibilities</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Autonomous Navigation" text="AI enables rockets to navigate autonomously, making precise course corrections and avoiding obstacles during their journey. This capability enhances safety and efficiency in space missions." />
      <Feature title="Payload Optimization" text="With AI algorithms, rockets can optimize the distribution and deployment of payloads, ensuring efficient utilization of resources and maximizing mission objectives." />
      <Feature title="Anomaly Detection" text="AI-based anomaly detection systems monitor various parameters and sensor data during rocket operations. They can identify and alert mission controllers about any abnormal behavior or malfunctions." />
    </div>
  </div>
);
export default WhatGPT3;
